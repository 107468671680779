import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Stage, Layer, Rect, Text, Line } from 'react-konva';

import config from '../config.js';
import { dateOnlyToString } from '../helper.js';
import  '../styles/global.css';

// Components
import Layout from '../components/Layout';
import MyButton from '../components/MyButton';
import EventBox from '../components/EventBox';

const hashCode = (event) => {
  const eventUniqueString = `${event.className}${event.teacher}${event.fromDatetime}${event.subject}`
  var hash = 0, i, chr;
  if (eventUniqueString.length === 0) return hash;
  for (i = 0; i < eventUniqueString.length; i++) {
    chr   = eventUniqueString.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};


const minutesDifference = (time1, time2) => {
  const date1 = new Date(time1);
  const date2 = new Date(time2);

  const difms = date2 > date1 ? date2 - date1 : date1 - date2;

  const difmins = Math.floor(difms/60000);

  return difmins;
}

export default function Doppelzimmer() {
  const labels = [
    '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
    '21:00', '22:00'
  ]

  const rooms = [
    '204', '205', '207', '307', '404', '405'
  ]

  const [events, setEvents] = useState([
    {
      events: [],
      doubleRoom: '',
    }
  ]);

  const [selectedEvent, setSelectedEvent] = useState({
    className: '',
    teacher: '',
    subject: '',
    fromDatetime: '2021-1-1 00:00',
    toDatetime: '2021-1-1 01:00',
    room: '',
  })

  const [canvasHeight, setCanvasHeight] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [dayOffset, setDayOffset] = useState(0);
  const [eventBoxShown, setEventBoxShown] = useState(false);

  const canvasDiv = useCallback(node => {
    if (node !== null) {
      setCanvasHeight(node.getBoundingClientRect().height);
      setCanvasWidth(node.getBoundingClientRect().width);
    }
  }, [])

  // On load
  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, [dayOffset])

  // Utility functions
  const populateEvents = (responseData) => {
    let newEvents = [
      {
        events: responseData.filter((event) => event.room === '204a' || event.room === '204b'),
        doubleRoom: '204',
      },
      {
        events: responseData.filter((event) => event.room === '205a' || event.room === '205b'),
        doubleRoom: '205',
      },
      {
        events: responseData.filter((event) => event.room === '207a' || event.room === '207b'),
        doubleRoom: '207',
      },
      {
        events: responseData.filter((event) => event.room === '307a' || event.room === '307b'),
        doubleRoom: '307',
      },
      {
        events: responseData.filter((event) => event.room === '404a' || event.room === '404b'),
        doubleRoom: '404',
      },
      {
        events: responseData.filter((event) => event.room === '405a' || event.room === '405b'),
        doubleRoom: '405',
      },
    ]

    newEvents.forEach((room, i) => {
      room.events.forEach((event, j) => {
        const curEventhash = hashCode(event);
        //Check if other both rooms are needed by finding twin event
        if (room.events.filter((innerEvent) => hashCode(innerEvent) === curEventhash).length > 1) {
          room.events[j] = {...event, color: '#FFC09F'}
        } else {
          room.events[j] = {...event, color: '#7EC4CF'}
        }
      })
    })

    setEvents(newEvents);
  }

  const getDateWithOffset = () => {
    let date = new Date();
    date.setDate(date.getDate() + dayOffset);

    return date;
  }

  const startpoint = () => {
    let startpoint = getDateWithOffset()
    startpoint.setHours(7, 0, 0, 0);

    return startpoint;
  }

  // API functions
  const getEvents = async () => {
    let now = getDateWithOffset();
    let tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let queryString = `?building=102&fromDatetime=${dateOnlyToString(now)}&toDatetime=${dateOnlyToString(tomorrow)}`;

    try {
      const response = await fetch (config.url + 'event/' + queryString, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
      });

      if (response.status !== 200) {
        throw Error('Something didn\'t work.', response);
      }

      let responseData = await response.json();
      responseData.forEach((event, index) => {
        responseData[index].fromDatetime = responseData[index].fromDatetime.replace('T', ' ').replace('Z', '');
        responseData[index].toDatetime = responseData[index].toDatetime.replace('T', ' ').replace('Z', '');
      })

      populateEvents(responseData);
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <Layout>
      { eventBoxShown ? 
        <EventBox event={selectedEvent} onClose={() => setEventBoxShown(false)} /> : null }
      <div className="legend">
        <Stage width={400} height={200}>
          <Layer>
            <Rect
              x={10}
              y={10}
              width={20}
              height={20}
              fill='#FFC09F'
            />
            <Text
              text=" = offen"
              fontSize={20}
              x={40}
              y={10}
            />
            <Rect
              x={10}
              y={40}
              width={20}
              height={20}
              fill='#7EC4CF'
            />
            <Text
              text=" = geschlossen"
              fontSize={20}
              x={40}
              y={40}
            />
          </Layer>
        </Stage>
      </div>
      <div className="timeline-head">
        <div className="left">
          <MyButton
            text="Tag zurück"
            onClick={() => dayOffset > 0 ? setDayOffset(dayOffset - 1) : null}
          />
        </div>
        <div>
          <p className="date">{`${getDateWithOffset().getDate()}.${getDateWithOffset().getMonth()+1}`}</p>
        </div>
        <div className="right">
          <MyButton
            text="Tag vorwärts"
            onClick={() => dayOffset < 7 ? setDayOffset(dayOffset + 1) : null}
          />
        </div>
      </div>
      <div className="room-bar">
        { rooms.map((room) => (
          <div className="room-column">
            <div className="room-title">
              <div className="room-name">
                <h2>{room}</h2>
              </div>
              <div className="room-section">
                <div>
                  <h3 style={{marginLeft: "3rem"}}>a</h3>
                </div>
                <div>
                  <h3 style={{marginRight: "3rem"}}>b</h3>
                </div>
              </div>
            </div>
            <div ref={canvasDiv} className="canvas">
              <Stage width={canvasWidth} height={canvasHeight}>
                <Layer>

                  {
                    labels.map((label, index) => (
                      <>
                        <Text 
                          text={label} 
                          x={10} 
                          y={((canvasHeight)/labels.length) * index}
                        />
                        <Line
                          x={45}
                          y={4 + ((canvasHeight)/labels.length) * index}
                          points={[ 0, 0, 220, 0 ]}
                          stroke="black"
                          strokeWidth={1}
                        />
                      </>
                    )) 
                  }
                </Layer>
                <Layer>
                  {
                    events[0].events.length !== 0 ?
                      events.filter((event) => event.doubleRoom === room)[0].events
                      .map((event, index) => (
                        <>
                          <Rect
                            key={`${event.id}`}
                            id={`${event.id}`}
                            x={event.room === `${room}a` ? 50 : 160} 
                            y={4+minutesDifference(event.fromDatetime, startpoint())*(canvasHeight/labels.length/60)} 
                            width={100} 
                            height={minutesDifference(event.toDatetime, event.fromDatetime)*(canvasHeight/labels.length/60)} 
                            fill={event.color}
                            opacity={0.7}
                            onClick={() => { 
                              setSelectedEvent(event);
                              setEventBoxShown(true);
                            }}
                          />
                          <Text
                            text={`${event.fromDatetime.split(' ')[1].slice(0,5)} - ${event.toDatetime.split(' ')[1].slice(0,5)}`}
                            x={event.room === `${room}a` ? 70 : 180} 
                            y={minutesDifference(event.fromDatetime, startpoint())*(canvasHeight/labels.length/60) + (minutesDifference(event.toDatetime, event.fromDatetime)*(canvasHeight/labels.length/60))/2} 
                            fontSize={12}
                            onClick={() => { 
                              setSelectedEvent(event);
                              setEventBoxShown(true);
                            }}
                          />
                        </>
                      )) : 
                      null
                  }
                </Layer>
              </Stage>

            </div>
          </div>
        ))
        }
      </div>
    </Layout>
  );
}
