import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { processDateForTable } from '../helper';
import  '../styles/global.css';

export default function EventBox({ event, onClose }) {
  return (
    <div className="hover-box">
      <div className="close-icon">
        <FaTimes onClick={onClose} size={25} fill="red"/>
      </div>
      <h2>Buchung</h2>
      <div className="event-information">
        <p className="titles">
          <b>Klasse:</b><br/>
          <b>Dozent:</b><br/>
          <b>Fach:</b><br/>
          <b>Zeitraum:</b><br/>
          <b>Zimmer:</b>
        </p>
        <p className="fields">
          {event.className}<br/>
          {event.teacher}<br/>
          {event.subject}<br/>
          {`${processDateForTable(event.fromDatetime).time} - ${processDateForTable(event.toDatetime).time}`}<br/>
          {event.room}
        </p>
  </div>
    </div>
  )
}
